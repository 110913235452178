import "./index.scss";
import { isNormal } from "./data.js";
const ball = document.querySelectorAll(".wind-element");
const openPdfButton = document.getElementById("openPdfBtn");
ball.forEach((ele, i) => {
  ele.classList.add(`animated-${i}`);
  ele.addEventListener("animationend", () => {
    ele.classList.remove(`animated-${i}`);
    let random = Math.random() * 5 + 3;
    ele.style.animationDuration = `${random}s`;
    void ele.offsetWidth;
    ele.classList.add(`animated-${i}`);
    isNormal[i] = !isNormal[i];
    ele.style.animationDirection = isNormal[i] ? "normal" : "reverse";
  });
});

openPdfButton.addEventListener("click", () => {
  const pdfUrl = "/menu.pdf";
  window.open(pdfUrl, "_blank");
});
